import React from "react";
import styles from './style.module.scss';
const Consult = () => {
  return (
    <div className={styles.consultTab}>
      <div className={styles.h1}>法律咨询</div>
      <div className={styles.todoList}>
        <div>根据您的具体情况，提供专业法律咨询服务，回答企业和个人都关心的法律问题</div>
      </div>
      <div className={styles.demo}>
        <div className={styles.demoLeft}>体验DEMO</div>
        <div className={styles.demoRight}>
          <img src={'/img/play.png'} alt="" />
        </div>
      </div>

      <div className={`${styles.h1} ${styles.pdf}`}>PDF助手</div>
      <div className={styles.todoList}>
        <div>PDF助手可以帮助您快速阅读PDF文档，获取内容概要和您关心的重点信息，提高您的办公和学习效率</div>
      </div>
      <div className={styles.demo}>
        <div className={styles.demoLeft}>体验DEMO</div>
        <div className={styles.demoRight}>
          <img src={'/img/play.png'} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Consult;