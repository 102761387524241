import React from "react";
import styles from './style.module.scss';

const Digital = () => {
  return (
    <div className={styles.digitalTab}>
      <div className={styles.h1}>虚拟人</div>
      <div className={styles.todoList}>
        <div>为企业，个人提供虚拟人技术解决方案，并将其应用于多种场景，如：企业宣传，知识讲解，直播间互动等</div>
      </div>
    </div>
  );
};

export default Digital;