import React, { useEffect, useRef, useState } from 'react';

import styles from './style.module.scss';
import Cooperation from './components/cooperation/index.tsx';
import Consult from './components/consult/index.tsx';
import Digital from './components/digital/index.tsx';
import axios from 'axios';
import Dialog from 'rc-dialog';
import 'rc-dialog/assets/index.css'

const tabs = [
  {
    key: 'cooperation',
    label: 'AI智能协作',
    component: <Cooperation />,
  },
  {
    key: 'consult',
    label: '机器人对话咨询',
    component: <Consult />,
  },
  {
    key: 'digital',
    label: '虚拟数字人',
    component: <Digital />,
  }
]

const advantageList = [
  {
    label: '因为看见，所以相信',
    des: '开源大模型数量众多，各模型的适用场景，配置要求及模型缺陷差异很大，通过酷点形成了完善的定制化模型解决方案',
    img: '/img/advantage-item1.png',
  },
  {
    label: 'AI+业务应用',
    des: '将垂直领域知识图谱与大模型技术相结合，有效保留大模型能力，降低概率模型带来的不确定性，实现了专业领域中AIGC的真正商业化',
    img: '/img/advantage-item2.png',
  },
  {
    label: '拥有领域顶尖技术专家',
    des: '国际化的大模型专家团队与国内经验丰富的商业化运营团队携手推进大模型私有化部署，充分保障方案的商业化能力',
    img: '/img/advantage-item3.png',
  },
  {
    label: '自研有效降低配置成本',
    des: '通过自研技术，使用较低配置的显卡来解决算力不足的问题，有效降低中小企业大模型私有化部署硬件成本60%-80%',
    img: '/img/advantage-item4.png',
  }
];
const Home = () => {
  const applicationRef = useRef(null);
  const advantageRef = useRef(null);
  const contactRef = useRef(null);
  const headerRef = useRef(null);
  const [active, setActive] = useState('cooperation');
  const [visible, setVisible] = useState(false);
  const [form, setForm] = useState({
    name: '',
    info: ''
  });
  const [formErr, setFormErr] = useState({
    name: '',
    info: ''
  });

  const changeInfoHandle = (e: any) => {
    setForm({...form, info: e.target.value})
    setFormErr({
      ...formErr,
      info: '',
    })
  };
  const changeNameHandle = (e: any) => {
    setForm({...form, name: e.target.value})
    setFormErr({
      ...formErr,
      name: '',
    })
  };
  const submitHandle = () => {
    console.log(form)
    if(!(/^1[34578]\d{9}$/.test(form.name))){
      setFormErr({
        ...formErr,
        name: '请输入正确的手机号码'
      })
      return
    } else if (!form.info) {
      setFormErr({
        ...formErr,
        info: '请输入内容'
      })
      return
    }
    axios.post('https://api.icoold.com/api/info', form).then(res => {
      if (res.data.code === 200) {
        console.log('提交成功')
        setVisible(true)
      }
    })
  };

  const scrollToAnchor = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    window.addEventListener('scroll',() => {
      if (window.scrollY > 20) {
        (headerRef?.current as unknown as HTMLElement).style.backgroundColor = 'rgba(35,35,35,0.5)';
      } else {
        (headerRef?.current as unknown as HTMLElement).style.backgroundColor = 'transparent';
      }
    });

    return () => {
      window.removeEventListener('scroll', () => null)
    }
    
  }, []);
  return (
    <div className={styles.containerWrap}>
      <div className={styles.container}>
        <div className={styles.headerWrap} ref={headerRef}>
          <header className={`${styles.header} flex-row justify-between align-center`} >
            <img src='/img/logo.png' alt="" />
            <div className={`${styles.navs} flex-row align-center`}>
              <span onClick={() => scrollToAnchor(applicationRef)}>行业应用</span>
              <span onClick={() => scrollToAnchor(advantageRef)}>技术优势</span>
              <span onClick={() => scrollToAnchor(contactRef)}>关于我们</span>
              <div className='flex-row align-center' style={{cursor: 'not-allowed'}}>
                <span>中国站</span>
                <img src="/img/dropdownIcon.png" alt="" />
              </div>
            </div>
          </header>
        </div>
        <div className={styles.introduce}>
          <div className={styles.title}>生成式AI企业级解决方案</div>
          <div className={styles.subTitle}>Enterprise Solutions</div>
          <div className={`${styles.list} flex-row`}>
            <div className={`${styles.item} flex-row`}>
              <div className={styles.circleDot}></div>
              <div className={`flex-col`}>
                <div className={styles.itemTitle}>高性能</div>
                <div className={styles.itemSubTitle}>High performance</div>
              </div>
            </div>
            <div className={`${styles.item} flex-row`}>
              <div className={styles.circleDot}></div>
              <div className={`flex-col`}>
                <div className={styles.itemTitle}>低成本</div>
                <div className={styles.itemSubTitle}>Low cost</div>
              </div>
            </div>
            <div className={`${styles.item} flex-row`}>
              <div className={styles.circleDot}></div>
              <div className={`flex-col`}>
                <div className={styles.itemTitle}>可定制</div>
                <div className={styles.itemSubTitle}>Customizable</div>
              </div>
            </div>
          </div>
          <div className={styles.btn}>获取解决方案</div>
          <div className={`${styles.boxList} align-center justify-between`}>
            <div className={`${styles.box} flex-col`}>
              <div className={`${styles.summary} flex-row align-center`}>
                <img src='/img/introduce-box-item1.png' alt="" />
                <span>定制企业级AI解决方案</span>
              </div>
              <div className={styles.des}>结合领域专业数据或企业私有数据，微调LLM，打造符合企业个性化需求的AI解决方案</div>
            </div>
            <div className={`${styles.box} flex-col align-center`}>
              <div className={`${styles.summary} flex-row align-center`}>
                <img src='/img/introduce-box-item2.png' alt="" />
                <span>AI核心能力</span>
              </div>
              <div className={styles.des}>不同行业场景下，将AI对话、AI写作、AI分析、AI绘图、AI翻译、AI监控等深度赋能行业发展，多领域推出全球领先的创新技术和产品</div>
            </div>
            <div className={`${styles.box} flex-col align-center`}>
              <div className={`${styles.summary} flex-row align-center`}>
                <img src='/img/introduce-box-item3.png' alt="" />
                <span>私有化部署</span>
              </div>
              <div className={styles.des}>快速部署一个类似于ChatGPT的企业级 LLM应用，保证企业行业数据的安全性和隐私性</div>
            </div>
          </div>
        </div>
        <div className={`${styles.tabs}`} ref={applicationRef} style={{backgroundImage: `url(/img/tabs-${active}.png)`}}>
          <div className={`${styles.tabList} flex-row align-center justify-center`}>
            {tabs.map(item => (
              <div onClick={() => setActive(item.key)} className={`${styles.tab} ${active === item.key? styles.active: ''}`} key={item.key} >{item.label}</div>
            ))}
          </div>
          <div className={styles.tabContent}>{tabs.find((item) => item.key === active)?.component}</div>
        </div>

        <div className={styles.advantage} ref={advantageRef}>
          <div className={styles.title}>我们的优势</div>
          <div className={styles.subTitle}>进驻前沿科技世界，了解AI人工智能的点滴</div>
          <div className={styles.list}>
            {advantageList.map(item => (
              <div className={styles.listItem} key={item.label}>
                <img src={item.img} alt="" />
                <div className={styles.pdleft32}>
                  <div className={styles.label}>{item.label}</div>
                  <div className={styles.splitLine}></div>
                  <div className={styles.des}>{item.des}</div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className={styles.contact} ref={contactRef}>
          <div className={styles.title}>联系专家团队</div>
          <div className={styles.subTitle}>为您制定解决方案</div>
          <div className={styles.formWrap}>
            <div className={styles.form}>
              <div className={styles.inputWrap}>
                <div className={styles.label}>联系电话</div>
                <input type="text" onChange={changeNameHandle} className={styles.input} placeholder='请输入您的电话' maxLength={11} />
                <div className={styles.formItemError}>{formErr.name}</div>
              </div>
              <div className={styles.inputWrap}>
                <div className={styles.label}>留言</div>
                <textarea onChange={changeInfoHandle} className={styles.input} placeholder='请输入您想了解的内容'></textarea>
                <div className={styles.formItemError}>{formErr.info}</div>
              </div>
            </div>
            <div className={styles.formTop}></div>
            <div className={styles.formBottom}></div>
          </div>
          <div className={styles.submit} onClick={submitHandle}>提交</div>
        </div>

        <div className={styles.footer}>
          <img src='/img/logo.png' alt="" />
          <div className={styles.copyRight}>
            <div>Copyright 2024 四川酷点网路科技有限公司版权所有</div>
            <div onClick={() => window.location.href="https://beian.miit.gov.cn/#/Integrated/index"} style={{cursor: 'pointer'}}>蜀ICP备2020033881号  增值电信经营许可证：川B2-20200479</div>
            <div>地址：成都市锦江区芙蓉西路502号</div>
          </div>
        </div>
        <Dialog visible={visible} destroyOnClose onClose={() => setVisible(false)} closeIcon={<img className={styles.closeIcon} src="/img/close.png" alt="" />}>
          <div className={styles.dialogContainer}>
            <img className={styles.successIcon} src="/img/success.png" alt="" />
            <span className={styles.successText}>提交成功</span>
            <span className={styles.successDes}>已经收到您提交的信息，我们会尽快与您取得联系，谢谢</span>
            <div className={styles.successBtn} onClick={() => setVisible(false)}>我知道了</div>
          </div>
        </Dialog>
      </div>
    </div>
  )
}
export default Home;
