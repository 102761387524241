import React, {Component} from 'react'
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'

import Home from './view/home/index.tsx'
class App extends Component {
    render() {
        return (
            <Router>
                <div>
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Redirect from="/" to="/" />
                    </Switch>
                </div>
            </Router>
        )
    }
}

export default App
