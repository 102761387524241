import React from "react";
import styles from './style.module.scss';

const Cooperation = () => {
  return (
    <div className={styles.cooperationTab}>
      <div className={styles.h1}>AIGC+智慧眼镜</div>
      <div className={styles.todoList}>
        <div>工业互联网数据可视化，实时掌握现场信息，实现有效果工作信息传递</div>
        <div>行业工作减少培训时间、提供培训质量、降低培训费用</div>
        <div>供应链远程管理，远程翻译评估，流程审核，质量验收</div>
      </div>
      <div className={styles.des}>AIGC赋能模式，可以轻量化前置到各个业务角落；其次，AIGC作业是基于全量的知识库，作业质量基本可以得到保障，不依赖人工作业的参差不齐；AIGC是计算机算力提供，可以7*24服务，实现秒级的响应时效；最后，对于企业来说，AIGC能最大化释放人力资源，投入到更高阶的生产作业活动中。</div>
    </div>
  );
};

export default Cooperation;